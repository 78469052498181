import React from 'react';
import { useApiClient } from "../api";
import omit from 'lodash/fp/omit';
import {
  FetchState,
  fetchLoading,
  fetchSuccess,
  fetchError,
  FetchStateType,
  stateHasData,
  stateHasError
} from "@openstax/ts-utils/fetch";
import {
  StatefulAssessmentResponse,
  StatefulExerciseResponse
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/student";
import { Exercise } from "./Exercise";
import * as AssessmentComponents from '@openstax/assessment-components';
import * as UIComponents from '@openstax/ui-components';
import { useQuery } from "../../routing/useQuery";
import * as Sentry from '@sentry/react';
import { makeActivityProgressMessage } from '@openstax/lti';
import {getCorrectnessVariant, BackendQuestionFeedbackState, useQuestionState} from '../utils/exercises';

// TODO - consider passing assessment.attempt_id all queries
const useReadExercise = (assessment: StatefulAssessmentResponse, exercise_uuid: string) => {
  const apiClient = useApiClient();
  const [state, setState] = React.useState<FetchState<StatefulExerciseResponse, string>>(fetchLoading());

  React.useEffect(() => {
    setState(previous => fetchLoading(previous));
    apiClient.apiV0ReadExercise({
      params: {assessment_id: assessment.id, exercise_uuid},
      query: {assessment_attempt: assessment.attempt_id}
    })
      .then(response => response.acceptStatus(200).load())
      .then(response => {
        setState(fetchSuccess(response));
      })
      .catch((e) => {
        Sentry.captureException(e);
        setState(previous => fetchError('error loading exercise', previous));
      })
      ;
  }, [apiClient, assessment, exercise_uuid]);

  return state;
};

const useAnswerQuestion = (assessment: StatefulAssessmentResponse, exercise_uuid: string) => {
  const apiClient = useApiClient();
  const [inFlight, setInFlight] = React.useState<{
    [key: string]: FetchState<BackendQuestionFeedbackState, string>;
  }>({});

  const answerQuestion = React.useCallback(({id, answer_id}: {id: number; answer_id: number}) => {
    setInFlight(previous => ({...previous, [id]: fetchLoading(previous[id])}));

    apiClient.apiV0CreateAnswer({
      params: {assessment_id: assessment.id, exercise_uuid, question_id: id.toString()},
      payload: {response_id: answer_id, assessment_attempt: assessment.attempt_id}
    })
      .then(response => response.acceptStatus(201).load())
      .then(response => {
        setInFlight(previous => ({...previous, [id]: fetchSuccess({
          exercise_uuid, id,
          answer_state: response
        })}));

        window.parent?.postMessage(makeActivityProgressMessage({
          id: assessment.id, isComplete: response.assessment_complete
        }), '*');
      })
      .catch((e) => {
        Sentry.captureException(e);
        setInFlight(previous => ({
          ...previous,
          // TODO - if there is a meaningful error message on the response we should use that
          [id]: fetchError('there was a problem saving that response', previous[id])
        }));
      });

  }, [assessment, exercise_uuid, apiClient, setInFlight]);

  const dismissAnswerStateType = React.useCallback((state: FetchStateType, id: string) => {
    setInFlight(previous => previous[id]?.type === state
      ? omit(id, previous)
      : previous
    );
  }, [setInFlight]);

  return [inFlight, answerQuestion, dismissAnswerStateType] as const;
};

export const Assessment = ({ assessment }: { assessment: StatefulAssessmentResponse }) => {
  const [
    questionStateHash,
    questionStateList,
    firstQuestionNumByExercise,
    updateQuestionState,
    setPendingResponse,
    setApiPending
  ] = useQuestionState(assessment);

  const query = useQuery();
  const completeAssessment = React.useCallback(() => {
    if (typeof query.return_url === 'string') {
      window.location.href = query.return_url;
    }
  }, [query]);

  const [showCompletionStatus, setShowCompletionStatus] =
    React.useState<boolean>(questionStateList.every((question) => question.is_completed));
  const initialQuestionIndex = questionStateList.findIndex(q => !q.is_completed);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState<number>(
    initialQuestionIndex >= 0 ? initialQuestionIndex : 0
  );
  const exerciseState = useReadExercise(assessment, questionStateList[currentQuestionIndex].exercise_uuid);
  const [savingAnswers, saveAnswer, dismissAnswerState] = useAnswerQuestion(
    assessment,
    questionStateList[currentQuestionIndex].exercise_uuid
  );

  React.useEffect(() => {
    Object.entries(savingAnswers).forEach(([question_id, state]) => {
      if (state.type === FetchStateType.LOADING) {
        setApiPending(question_id);
      } else if (state.type === FetchStateType.SUCCESS) {
        updateQuestionState(state.data);
        dismissAnswerState(FetchStateType.SUCCESS, question_id);
      } else if (state.type === FetchStateType.ERROR) {
        setApiPending(question_id, false);
      }
    });
  }, [savingAnswers, dismissAnswerState, setApiPending, updateQuestionState]);

  React.useEffect(() => {
    if (stateHasData(exerciseState) && exerciseState.type === FetchStateType.SUCCESS) {
      exerciseState.data.questions.forEach(question => updateQuestionState({
        id: question.id,
        exercise_uuid: exerciseState.data.uuid,
        answer_state: question.answer_state
      }));
    }
  }, [exerciseState, updateQuestionState]);

  const [showErrorModal, setShowErrorModal] = React.useState(false);

  React.useEffect(() => {
    if (exerciseState.type === FetchStateType.ERROR) {
      setShowErrorModal(true);
    }
  }, [exerciseState]);
  return <>
    <AssessmentComponents.ProgressBar
      activeIndex={showCompletionStatus ? questionStateList.length : currentQuestionIndex}
      goToStep={(i: number) => {
        const isStatusStep = i === questionStateList.length;
        setShowCompletionStatus(isStatusStep);
        if (!isStatusStep) {
          setCurrentQuestionIndex(i);
        }
      }}
      steps={[
        ...questionStateList.map(
          (question) => ({
            variant: getCorrectnessVariant(question, assessment.feedback !== 'NONE')
          })
        ),
        {variant: 'isStatus'},
      ]}
    />

    {exerciseState.type === FetchStateType.LOADING
      ? <AssessmentComponents.Loader />
      : null
    }

    <UIComponents.ErrorModal
      show={showErrorModal}
      onModalClose={() => setShowErrorModal(false)}
    />

    {Object.entries(savingAnswers).map(([question_id, state]) => stateHasError(state) ?
      <UIComponents.ErrorModal
        show={true}
        onModalClose={() => dismissAnswerState(FetchStateType.ERROR, question_id)}
        key={question_id}
      /> : null)}

    {!showCompletionStatus && exerciseState.type === FetchStateType.SUCCESS ? <Exercise
      hasFeedback={assessment.feedback !== 'NONE'}
      hasMultipleAttempts={assessment.multiple_attempts}
      data={exerciseState.data}
      answerState={questionStateHash}
      onNextStep={(index: number) => {
        const isStatusStep = index + 1 === questionStateList.length;
        setShowCompletionStatus(isStatusStep);
        if (!isStatusStep) {
          setCurrentQuestionIndex(index + 1);
        }
      }}
      savePendingResponse={(question_id) => {
        if (typeof question_id === 'undefined') { return null; }
        const data = questionStateHash[question_id.toString()];
        if (typeof data.answer_id === 'undefined') { return null; }
        saveAnswer({ id: data.id, answer_id: data.answer_id });
      }}
      index={currentQuestionIndex}
      setPendingResponse={setPendingResponse}
      numberOfQuestions={assessment.exercises.length}
      firstQuestionNumber={firstQuestionNumByExercise[exerciseState.data.uuid]}
      assessmentId={assessment.id}
    /> : (showCompletionStatus && exerciseState.type === FetchStateType.SUCCESS ?
      <AssessmentComponents.CompletionStatus
        numberOfQuestions={questionStateList.length}
        numberCompleted={questionStateList.filter((question) => question.is_completed).length}
        handleClick={() => {
          const indexOfFirstIncomplete = questionStateList.findIndex((question) => !question.is_completed);
          if (questionStateList.every((question) => question.is_completed)) {
            completeAssessment();
          } else {
            setShowCompletionStatus(false);
            setCurrentQuestionIndex(indexOfFirstIncomplete);
          }
        }}
      /> : null)
    }
  </>;
};
