"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.managedAssessmentTypes = exports.licenses = void 0;
exports.licenses = {
    allRightsReserved: {
        holder: 'OpenStax',
        name: 'All Rights Reserved',
    },
    creativeCommonsAttribution: {
        holder: 'OpenStax',
        name: 'Creative Commons Attribution License',
        url: 'http://creativecommons.org/licenses/by/4.0/',
    }
};
exports.managedAssessmentTypes = {
    readingReinforcement: {
        title: 'Reinforcement questions',
        formatTitle: (resource) => resource && 'titleParts' in resource ? `${resource.titleParts.numberText} Reinforcement questions` : 'Reinforcement questions',
        description: 'Reinforcement questions have been closely aligned to textbook content and learning outcomes.',
        license: exports.licenses.allRightsReserved
    },
    preReadingReinforcement: {
        title: 'Pre-Reading Reinforcement questions',
        formatTitle: (resource) => resource && 'titleParts' in resource ? `${resource.titleParts.numberText} Pre-Reading Reinforcement questions` : 'Pre-Reading Reinforcement questions',
        description: 'Pre-Reading Reinforcement questions prime learning by reviewing foundational concepts.',
        license: exports.licenses.allRightsReserved
    },
    postReadingReinforcement: {
        title: 'Post-Reading Reinforcement questions',
        formatTitle: (resource) => resource && 'titleParts' in resource ? `${resource.titleParts.numberText} Post-Reading Reinforcement questions` : 'Post-Reading Reinforcement questions',
        description: 'Post-Reading Reinforcement questions reinforce learning by exercising key concepts.',
        license: exports.licenses.allRightsReserved
    },
    cumulativePractice: {
        title: 'Cumulative Practice',
        formatTitle: (resource) => resource && 'titleParts' in resource ? `${resource.titleParts.numberText} Cumulative Practice` : 'Cumulative Practice',
        description: 'A quiz made of 10 exercises randomly selected from {exercises_available} available end-of-chapter exercises in the textbook. Note: these do not include Reinforcement Questions, and question-level feedback is not available.',
        license: exports.licenses.creativeCommonsAttribution
    },
    integratedResourceQuestions: {
        title: 'Integrated Resource Questions',
        formatTitle: (resource) => resource && 'title' in resource && resource.title ? `Integrated Resource Questions: ${resource.title}` : 'Integrated Resource Questions',
        description: 'A brief quiz assessing students\' understanding or application of the individual ancillary (e.g. a video, supplemental reading, case study) to which it is tied.',
        license: exports.licenses.allRightsReserved,
    },
};
