import React from 'react';
import { createRoute, makeScreen } from "../../../core/services";
import { useApiClient, ApiClient } from "../../api";
import {
  FetchState,
  fetchLoading,
  fetchSuccess,
  FetchStateType,
  stateHasData,
} from "@openstax/ts-utils/fetch";
import {
  managedAssessmentPreviewUrlTemplate,
  managedAssessmentGroupPreviewUrlTemplate
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/integration.config";
import type {
  ManagedAssessmentSearchResult
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor";
import { useQuery } from "../../../routing/useQuery";
import { Loader, Exercise } from '@openstax/assessment-components';
import { useSetAppError } from '@openstax/ui-components';
import * as UI from '@openstax/ui-components';
import { ExerciseData, ExerciseQuestionData } from '@openstax/assessment-components/dist/src/types';
import { managedAssessmentTypes } from '@project/lambdas/build/src/assessmentTypeMetadata';
import { QueryParams } from "@openstax/ts-utils/routing";
import { useIsVisible } from '../../../hooks';
import {
  AssessmentFooter,
  AssessmentNav,
  AssessmentNavWrapper,
  AssessmentWrapper,
  HeaderWrapper,
  LayoutWrapper,
  NoExercisesWrapper,
  StyledButtonBar
} from './styled';

const NoExercisesFoundError = () =>
  <NoExercisesWrapper>
    <UI.Error heading=''>
      No exercises found for this activity.
    </UI.Error>
  </NoExercisesWrapper>;

type GetExercisesInput = Parameters<ApiClient['apiV0GetExercises']>[0];
type GetExercisesResponse = Awaited<ReturnType<typeof getGetExercises>>;

const getGetExercises = (apiClient: ApiClient, input: GetExercisesInput) => {
  return apiClient.apiV0GetExercises(input)
    .then((response) => response.acceptStatus(200).load());
};

const useGetExercises = (initialState: GetExercisesResponse, page: number, tags: string[]) => {
  const apiClient = useApiClient();
  const setAppError = useSetAppError();
  const [state, setState] = React.useState<FetchState<GetExercisesResponse, string>>(fetchSuccess(initialState));

  React.useEffect(() => {
    if ((stateHasData(state) && page === state.data.meta.currentPage) || state.type === FetchStateType.LOADING) {
      return;
    }

    setState(previous => fetchLoading(previous));
    getGetExercises(apiClient, {query: {tags, page: page.toString()}})
      .then((response) => { setState(
        fetchSuccess({
          ...response,
          items: [
            ...(stateHasData(state) ? state.data.items : []),
            ...response.items
          ]
        }),
      ); })
      .catch(setAppError)
    ;
  }, [apiClient, setAppError, tags, page, state]);

  return state;
};

type PreviewAssessmentInput = Parameters<ApiClient['apiV0PreviewAssessment']>[0];
type PreviewAssessmentResponse = Awaited<ReturnType<typeof getPreviewAssessment>>;

const getPreviewAssessment = (apiClient: ApiClient, input: PreviewAssessmentInput) => {
  return apiClient.apiV0PreviewAssessment(input)
    .then((response) => response.acceptStatus(200).load());
};

const usePreviewAssessment = (params: PreviewAssessmentInput['params']) => {
  const apiClient = useApiClient();
  const setAppError = useSetAppError();
  const query = useQuery() as QueryParams;
  const queryRef = React.useRef(query);
  const paramsRef = React.useRef(params);
  const [state, setState] = React.useState<FetchState<PreviewAssessmentResponse, string>>(fetchLoading());

  React.useEffect(() => {
    getPreviewAssessment(apiClient, {params: paramsRef.current, query: queryRef.current})
      .then((response) => { setState(fetchSuccess(response)); })
      .catch(setAppError)
    ;
  }, [apiClient, setAppError]);

  return state;
};

type PreviewAssessmentGroupInput = Parameters<ApiClient['apiV0PreviewAssessmentGroup']>[0];
type PreviewAssessmentGroupResponse = Awaited<ReturnType<typeof getPreviewAssessmentGroup>>;

const getPreviewAssessmentGroup = (apiClient: ApiClient, input: PreviewAssessmentGroupInput) => {
  return apiClient.apiV0PreviewAssessmentGroup(input)
    .then((response) => response.acceptStatus(200).load());
};

const usePreviewAssessmentGroup = (params: PreviewAssessmentGroupInput['params']) => {
  const apiClient = useApiClient();
  const setAppError = useSetAppError();
  const query = useQuery() as QueryParams;
  const queryRef = React.useRef(query);
  const paramsRef = React.useRef(params);
  const [state, setState] = React.useState<FetchState<PreviewAssessmentGroupResponse, string>>(fetchLoading());

  React.useEffect(() => {
    getPreviewAssessmentGroup(apiClient, {params: paramsRef.current, query: queryRef.current})
      .then((response) => { setState(fetchSuccess(response)); })
      .catch(setAppError)
    ;
  }, [apiClient, setAppError]);

  return state;
};

const formatAnswerData = (questions: ExerciseQuestionData[]) =>
  questions.map((q) => ({
    id: q.id,
    correct_answer_id: q.answers.find((a) => a.correctness === '1.0')?.id || '',
  }));

const questionStateFields = {
  available_points: '1.0',
  is_completed: true,
  answer_id: '1',
  free_response: '',
  feedback_html: '',
  correct_answer_feedback_html: '',
  attempts_remaining: 0,
  attempt_number: 1,
  incorrectAnswerId: 0,
};

type AssessmentTypeWithOptionalDescription = Omit<
  typeof managedAssessmentTypes[keyof typeof managedAssessmentTypes], 'description' | 'formatTitle'
> & Partial<
  Pick<typeof managedAssessmentTypes[keyof typeof managedAssessmentTypes], 'description'>
>;
type AssessmentTypeTitles = { [K in keyof typeof managedAssessmentTypes]: string; };
type AssessmentTypeKeys = keyof typeof managedAssessmentTypes;

const AssessmentGroupHeader = (props: {
  assessment: AssessmentTypeWithOptionalDescription;
  activities?: ManagedAssessmentSearchResult[];
  visibleActivity: string;
  setVisibleActivity?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const headerRef = React.useRef<HTMLDivElement | null>(null);
  const headerIsVisible = useIsVisible(headerRef);

  const activityTitles: AssessmentTypeTitles = React.useMemo(() => ({
    ...(Object.keys(managedAssessmentTypes) as AssessmentTypeKeys[]).reduce((acc, key) => {
      acc[key] = managedAssessmentTypes[key].title;
      return acc;
    }, {} as AssessmentTypeTitles),
    ...{
      preReadingReinforcement: "Pre-Reading Questions",
      postReadingReinforcement: "Post-Reading Questions"
    }
  }), []);

  const handleScrollTo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const id = e.currentTarget.hash.replace('#', '');
    const scrollTarget = document.getElementById(id);
    if (!scrollTarget) { return; }

    window.scrollTo({
      top: scrollTarget?.offsetTop - 89,
      behavior: 'smooth'
    });

    e.preventDefault();
  };

  return <>
    <AssessmentHeader {...props} size="large" showLicense={true} forwardRef={headerRef} />
    <AssessmentNavWrapper shadow={!headerIsVisible}>
      {props.activities ?
        <AssessmentNav>
          <StyledButtonBar>
            {props.activities.map((activity, index) =>
              <a
                href={`#activity-${index}`}
                key={index}
                onClick={handleScrollTo}
                {...(props.visibleActivity === `activity-${index}` && { 'data-selected': true })}
              >
                {activityTitles[activity.payload.assessmentType]}
              </a>)}
          </StyledButtonBar>
        </AssessmentNav>
        : null}
    </AssessmentNavWrapper>
  </>;
};

const AssessmentHeader = ({
  assessment: { title, description, license },
  showLicense = false, size = 'medium',
  forwardRef
}: {
  assessment: AssessmentTypeWithOptionalDescription;
  showLicense?: boolean;
  size?: 'large' | 'medium';
  forwardRef?: React.ForwardedRef<HTMLDivElement>;
}) => {
  const Header = size === 'large' ? 'h1' : 'h2';
  return <HeaderWrapper ref={forwardRef}>
    <Header>{title}</Header>
    <p>{description}</p>
    {showLicense ? <p>
      <strong>
        © {license.holder} {new Date().getFullYear()}. {
          'url' in license
            ? <a href={license.url} target="_blank" rel="noreferrer">{license.name}</a>
            : license.name
        }.
      </strong>
    </p> : null}
  </HeaderWrapper>;
};

const ExerciseWithRef = ({
  setToVisibleActivity,
  exercise,
  numberOfQuestions,
  questionNumber
}: {
  exercise: ExerciseData;
  numberOfQuestions: number;
  questionNumber: number;
  setToVisibleActivity?: () => void;
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const isVisible = useIsVisible(ref, {
    threshold: 0,
    rootMargin: '-49.5% 0px -49.5% 0px',
  });

  React.useEffect(() => {
    if (setToVisibleActivity && isVisible) {
      setToVisibleActivity();
    }
  }, [isVisible, setToVisibleActivity]);

  const questionStates = formatAnswerData(exercise.questions).reduce(
    (acc, answer) => {
      const { id, correct_answer_id } = answer;
      return {
        ...acc,
        [id]: { ...questionStateFields, correct_answer_id },
      };
    },
    {},
  );

  return <div ref={ref}>
    <Exercise
      key={exercise.uid}
      canAnswer={false}
      needsSaved={false}
      hasMultipleAttempts={false}
      onAnswerChange={() => undefined}
      onAnswerSave={() => undefined}
      onNextStep={() => undefined}
      apiIsPending={false}
      canUpdateCurrentStep={false}
      exercise={exercise}
      step={{
        id: 1,
        uid: exercise.uid,
        available_points: '1.0',
      }}
      questionNumber={questionNumber}
      numberOfQuestions={numberOfQuestions}
      questionStates={questionStates}
      show_all_feedback={true} />
  </div>;
};

const PreviewComponent = ({ exercises, setToVisibleActivity }: {
  exercises: ExerciseData[];
  setToVisibleActivity?: () => void;
}) => {
  if (!exercises.length) {
    return <NoExercisesFoundError />;
  }

  return <>
    {exercises.map((exercise, i) => {
      return (
        <ExerciseWithRef
          key={i}
          exercise={exercise}
          setToVisibleActivity={setToVisibleActivity}
          questionNumber={i + 1}
          numberOfQuestions={exercises.length}
        />
      );
    })}
  </>;
};

export const AssessmentDisplay = ({
  assessment,
  exercises,
  exerciseTags,
  assessmentHeaderOptions,
  group = false,
  activityId,
  setToVisibleActivity
}: {
  assessment: ManagedAssessmentSearchResult;
  exercises: PreviewAssessmentResponse['exercises'];
  exerciseTags: string[];
  assessmentHeaderOptions?: Pick<React.ComponentProps<typeof AssessmentHeader>, 'showLicense' | 'size'>;
  group?: boolean;
  setToVisibleActivity?: () => void;
  activityId?: string;
}) => {
  const [page, setPage] = React.useState(1);
  const exercisesState = useGetExercises(exercises, page, exerciseTags);

  return (
    <AssessmentWrapper group={group} id={activityId}>
      <AssessmentHeader assessment={assessment} {...assessmentHeaderOptions} />
      {exercisesState.type === FetchStateType.LOADING ? <Loader /> : null}
      {stateHasData(exercisesState) ? <>
        <PreviewComponent
          exercises={exercisesState.data.items}
          setToVisibleActivity={setToVisibleActivity}
        />

        {exercisesState.data.meta.currentPage < exercisesState.data.meta.totalPages ?
          <AssessmentFooter group={group}>
            <UI.Button
              onClick={() => setPage(page + 1)}
              disabled={exercisesState.type !== FetchStateType.SUCCESS}
            >Load more</UI.Button>
          </AssessmentFooter>
          : null}
      </> : null}
    </AssessmentWrapper>
  );
};

export const PreviewAssessment = (params: PreviewAssessmentInput['params']) => {
  const assessmentState = usePreviewAssessment(params);

  return (
    <LayoutWrapper>
      <AssessmentWrapper>
        {assessmentState.type === FetchStateType.LOADING ? <Loader /> : null}
        {assessmentState.type === FetchStateType.SUCCESS ?
          <AssessmentDisplay
            assessment={assessmentState.data.activity}
            exercises={assessmentState.data.exercises}
            exerciseTags={assessmentState.data.exerciseTags}
            assessmentHeaderOptions={{ size: 'large', showLicense: true }}
          />
          : null}
      </AssessmentWrapper>
    </LayoutWrapper>
  );
};

export const PreviewAssessmentGroup = (params: PreviewAssessmentInput['params']) => {
  const assessmentState = usePreviewAssessmentGroup(params);
  const [visibleActivity, setVisibleActivity] = React.useState('activity-0');

  return (
    <LayoutWrapper>
      <AssessmentWrapper>
        {assessmentState.type === FetchStateType.LOADING ? <Loader /> : null}
        {assessmentState.type === FetchStateType.SUCCESS ? <>
          <AssessmentGroupHeader
            assessment={assessmentState.data.activity}
            activities={assessmentState.data.assessments.map((i) => i.activity)}
            visibleActivity={visibleActivity}
            setVisibleActivity={setVisibleActivity}
          />
          {assessmentState.data.assessments.map(
            (assessment, i) =>
              <AssessmentDisplay
                key={i}
                assessment={assessment.activity}
                activityId={`activity-${i}`}
                setToVisibleActivity={() => setVisibleActivity(`activity-${i}`)}
                exercises={assessment.exercises}
                exerciseTags={assessment.exerciseTags}
                group={true}
              />
          )}
        </> : null}
      </AssessmentWrapper>
    </LayoutWrapper>
  );
};

export const previewAssessmentScreen = createRoute({
  name: 'PreviewScreen',
  path: managedAssessmentPreviewUrlTemplate,
  handler: makeScreen(PreviewAssessment),
});

export const previewAssessmentGroupScreen = createRoute({
  name: 'GroupPreviewScreen',
  path: managedAssessmentGroupPreviewUrlTemplate,
  handler: makeScreen(PreviewAssessmentGroup),
});
