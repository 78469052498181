import {launchScreen} from './Launch';
import { previewAssessmentScreen, previewAssessmentGroupScreen } from './Preview';
import { reviewAssessmentScreen } from './Review';

export const assessmentRoutes = () => ([
  launchScreen,
  previewAssessmentScreen,
  previewAssessmentGroupScreen,
  reviewAssessmentScreen,
]);
