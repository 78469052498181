"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assessmentSubmissionReviewTemplate = exports.managedAssessmentPreviewUrlTemplate = exports.managedAssessmentGroupPreviewUrlTemplate = exports.assessmentLaunchUrlTemplate = void 0;
/*
 * this is separate so it can have no dependencies and the frontend can import it without bundling the world
 */
exports.assessmentLaunchUrlTemplate = '/launch-assessment/:id';
exports.managedAssessmentGroupPreviewUrlTemplate = '/instructor/preview-group/:assessmentType';
exports.managedAssessmentPreviewUrlTemplate = '/instructor/preview/:assessmentType';
exports.assessmentSubmissionReviewTemplate = '/instructor/review/:id';
