import React from 'react';
import { StatefulAssessmentResponse } from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/student";
import {
  assessmentLaunchUrlTemplate
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/integration.config";
import { createRoute, makeScreen } from "../../core/services";
import { useApiClient } from "../api";
import {
  FetchState,
  fetchError,
  fetchLoading,
  fetchSuccess,
  FetchStateType,
  stateHasData,
  stateHasError
} from "@openstax/ts-utils/fetch";
import { Assessment } from "../components/Assessment";
import { useAuth } from "../../auth/useAuth";
import { useLaunchTokenData } from "../../auth/useTokenData";
import { useServices } from "../../core/context/services";
import { Loader } from '@openstax/assessment-components';
import styled from 'styled-components';
import { useSetAppError } from '@openstax/ui-components';
import { useTextResizeIntegration } from '../hooks/textResizeIntegration';
import { TextResizerValue } from '../types';
import { textResizerValueMap } from '../constants';
import { parseTextResizerValue } from '../helpers';

const AssessmentWrapper = styled.div<{ textSize: TextResizerValue }>`
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;

  ${(props: { textSize: TextResizerValue }) => `
    --content-text-scale: ${textResizerValueMap.get(props.textSize)};
  `}
`;

const useLaunchAssessment = (id: string) => {
  const apiClient = useApiClient();
  const setAppError = useSetAppError();
  const [state, setState] = React.useState<FetchState<StatefulAssessmentResponse, string>>(fetchLoading());
  const {launchToken} = useServices();

  React.useEffect(() => {
    if (typeof launchToken !== 'string') { return setState(fetchError('missing JWT token')); }

    apiClient.apiV0ReadAssessment({params: {id}, payload: launchToken})
      .then(response => response.acceptStatus(200, 201).load())
      .then(response => {
        response && setState(fetchSuccess(response));
      })
      .catch(setAppError)
    ;
  }, [apiClient, id, setAppError, launchToken]);

  return state;
};

export const Launch = ({id}: {id: string}) => {
  const authState = useAuth();
  const assessmentState = useLaunchAssessment(id);
  const tokenData = useLaunchTokenData();

  // working on aligning token claims to be snake case
  const parsedTextSize = parseTextResizerValue('text_size' in tokenData ? tokenData.text_size : tokenData.textSize);

  const [textSize, setTextSize] = React.useState<TextResizerValue>(parsedTextSize);
  useTextResizeIntegration(setTextSize);

  return <AssessmentWrapper textSize={textSize}>
    {assessmentState.type === FetchStateType.LOADING
      ? <Loader />
      : null
    }
    {stateHasError(assessmentState) ? <>
      <strong>{assessmentState.error}</strong><br />
      {stateHasError(authState) ? <strong>{authState.error}</strong> : null}
      {stateHasData(authState) && !authState.data ? <strong>no user session found</strong> : null}
    </> : null}
    {stateHasData(assessmentState) ? <Assessment assessment={assessmentState.data} /> : null}
  </AssessmentWrapper>;
};

/*
 * a student first arrives in the assessment UI we know the assessment ID but
 * not the correct version of the assessment to use, if there is a current attempt,
 * or what the status of that attempt is. before we can send the student to the right
 * place we load the assessment and assessment state from the api and then redirect them
 */
export const launchScreen = createRoute({name: 'LaunchScreen', path: assessmentLaunchUrlTemplate,
  handler: makeScreen(Launch)
});
